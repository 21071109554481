exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliations-js": () => import("./../../../src/pages/affiliations.js" /* webpackChunkName: "component---src-pages-affiliations-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-consumer-unit-js": () => import("./../../../src/pages/services/consumer-unit.js" /* webpackChunkName: "component---src-pages-services-consumer-unit-js" */),
  "component---src-pages-services-electric-heater-installations-js": () => import("./../../../src/pages/services/electric-heater-installations.js" /* webpackChunkName: "component---src-pages-services-electric-heater-installations-js" */),
  "component---src-pages-services-electrical-installation-condition-report-js": () => import("./../../../src/pages/services/electrical-installation-condition-report.js" /* webpackChunkName: "component---src-pages-services-electrical-installation-condition-report-js" */),
  "component---src-pages-services-fire-alarms-and-emergency-lighting-js": () => import("./../../../src/pages/services/fire-alarms-and-emergency-lighting.js" /* webpackChunkName: "component---src-pages-services-fire-alarms-and-emergency-lighting-js" */),
  "component---src-pages-services-maintenance-js": () => import("./../../../src/pages/services/maintenance.js" /* webpackChunkName: "component---src-pages-services-maintenance-js" */),
  "component---src-pages-services-smart-prepayment-and-card-meters-js": () => import("./../../../src/pages/services/smart-prepayment-and-card-meters.js" /* webpackChunkName: "component---src-pages-services-smart-prepayment-and-card-meters-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

